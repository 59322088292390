import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import { PLAN_TYPES } from '../user-account/user.model';
import { AuthService } from './auth.service';
import { UPSELL_CLOSE_EVENT } from 'app/shared/utils';
import { UPSELL_DATA } from 'app/global-components/upsell/upsell-data.data';
import { PRODUCT_TYPES } from 'app/shared/utils';
export enum FEATURE_TYPES {
    ADVANCED_CUSTOMIZATION = 'advanced_customization',
    ADVANCED_CUSTOMIZATIONS_NEW_CARD_DESIGNS = 'advanced_customizations_new_card_designs',
    CUSTOM_FONTS = 'custom_fonts',
    WHITE_LABEL = 'white_label',
    ENTRA_ID = 'entra_id',
    OKTA_SCIM = 'okta_scim',
    SALESFORCE = 'salesforce',
    DBC_CUSTOM_DOMAIN = 'dbc_custom_domain',
    DBC_WHITELABEL_ACCESS = 'dbc_whitelabel_access',
    DBC_SSO = 'dbc_sso',
    MULTI_LANG = 'multi_lang',
    LOCATION = 'location',
    ADVANCED_ANALYTICS = 'advanced_analytics',
    DBC_PREMIUM_LAYOUTS = 'dbc_premium_layouts',
    HUBSPOT = 'hubspot'
}

export interface EducationModalData {
    title: string;
    message: string;
    route?: string;
    primaryCta?: string;
    eventFromSource?: string;
    imageShow?: boolean;
    imageNameSource?: string;
    redirectLink?: string;
    closeEventSource?: UPSELL_CLOSE_EVENT;
}

@Injectable()
export class UpsellService {
    private minimumRequiredPlanList = {
        qr: PLAN_TYPES.Enterprise,
        retargeting: PLAN_TYPES.Enterprise,
        multi_lang: PLAN_TYPES.Pro,
        location: PLAN_TYPES.Pro,
        multi_user: PLAN_TYPES.Plus,
        multi_qr: PLAN_TYPES.Plus,
        multi_org: PLAN_TYPES.Enterprise,
        reseller_access: PLAN_TYPES.Enterprise,
        custom_domain: PLAN_TYPES.Plus,
        whitelabel_access: PLAN_TYPES.Enterprise,
        webhook: PLAN_TYPES.Enterprise,
        custom_slug: PLAN_TYPES.Enterprise,
        sso: PLAN_TYPES.Enterprise,
        form_response_sms: PLAN_TYPES.Enterprise,
        api: PLAN_TYPES.Pro,
        short_url: PLAN_TYPES.Pro,
        share_folder: PLAN_TYPES.Plus,
        manage_campaign_access: PLAN_TYPES.Enterprise,
        google_wallet: PLAN_TYPES.Lite,
        apple_wallet: PLAN_TYPES.Lite,
        bulk_upload: PLAN_TYPES.Lite,
        api_dbc: PLAN_TYPES.Team,
        mfa_access: PLAN_TYPES.Enterprise,
        plan_upgrade: PLAN_TYPES.Plus,
        spu: PLAN_TYPES.Pro,
        geolocation: PLAN_TYPES.Pro,
        geolocation_country: PLAN_TYPES.Pro,
        smart_rules: PLAN_TYPES.Pro,
        landing_page: PLAN_TYPES.Pro,

        // slg(sales led growth) upsell modals
        slg_upsell_modal_lite: PLAN_TYPES.Pro,
        slg_upsell_modal_lite_mobile: PLAN_TYPES.Pro,
        slg_upsell_modal_starter: PLAN_TYPES.Lite,
        slg_upsell_modal_starter_mobile: PLAN_TYPES.Lite,
        slg_upsell_modal_pro: PLAN_TYPES.Plus,
        slg_upsell_modal_pro_mobile: PLAN_TYPES.Plus,

        /* DBC Features */
        advanced_customization: PLAN_TYPES.Business,
        advanced_customizations_new_card_designs: PLAN_TYPES.Business,
        custom_fonts: PLAN_TYPES.BusinessPlus,
        white_label: PLAN_TYPES.BusinessPlus,
        entra_id: PLAN_TYPES.BusinessPlus,
        salesforce: PLAN_TYPES.BusinessPlus,
        hubspot: PLAN_TYPES.BusinessPlus,
        dbc_custom_domain: PLAN_TYPES.BusinessPlus,
        dbc_whitelabel_access: PLAN_TYPES.BusinessPlus,
        dbc_sso: PLAN_TYPES.BusinessPlus,
        unlock_more_layouts: PLAN_TYPES.Team,
        cards_integrations: PLAN_TYPES.Team,
        advanced_analytics: PLAN_TYPES.DBCNTeam,
        cards_limit_increase: PLAN_TYPES.DBCNTeam,
        dbc_premium_layouts: PLAN_TYPES.Team,
        premium_customization: PLAN_TYPES.DBCNTeam,
        premium_customizations_new_card_designs: PLAN_TYPES.DBCNTeam,
    }
    private visibleSource = new BehaviorSubject<boolean>(false);
    visible$: Observable<boolean> = this.visibleSource.pipe(distinctUntilChanged());
    private messageSource = new BehaviorSubject<string>('');
    message$: Observable<string> = this.messageSource.pipe(distinctUntilChanged());
    private titleSource = new BehaviorSubject<string>('');
    title$: Observable<string> = this.titleSource.pipe(distinctUntilChanged());
    private primaryCtaSource = new BehaviorSubject<string>('Upgrade Plan');
    primaryCta$: Observable<string> = this.primaryCtaSource.pipe(distinctUntilChanged());
    private routeToSource = new BehaviorSubject<string>('');
    routeTo$: Observable<string> = this.routeToSource.pipe(distinctUntilChanged());
    public eventSource = new BehaviorSubject<string>('');
    source$: Observable<string> = this.eventSource.pipe(distinctUntilChanged());
    private imageShowSource = new BehaviorSubject<boolean>(false);
    imageShow$: Observable<boolean> = this.imageShowSource.pipe(distinctUntilChanged());
    private imageNameSource = new BehaviorSubject<string>('');
    imageName$: Observable<string> = this.imageNameSource.pipe(distinctUntilChanged());
    private redirectLinkSource = new BehaviorSubject<string>('');
    redirectLink$: Observable<string> = this.redirectLinkSource.pipe(distinctUntilChanged());
    public eventOnClose = new Subject<string>();
    closeEvent$: Observable<string> = this.eventOnClose.pipe();
    public isTrialSwitchModal = new BehaviorSubject<string>('');
    isTrialSwitchModal$: Observable<string> = this.isTrialSwitchModal.pipe();
    private allowDirectUpgrade = new BehaviorSubject<boolean>(false);
    allowDirectUpgrade$: Observable<boolean> = this.allowDirectUpgrade.pipe(distinctUntilChanged());
    private feature = new BehaviorSubject<string>('');
    feature$: Observable<string> = this.feature.pipe(distinctUntilChanged());

    // observable for upsell modal v2
    private visibleSourceV2 = new BehaviorSubject<boolean>(false);
    visibleV2$: Observable<boolean> = this.visibleSourceV2.pipe(distinctUntilChanged());
    private dataV2 = new BehaviorSubject<Object>({});
    dataV2$: Observable<Object> = this.dataV2.pipe(distinctUntilChanged());

    // observable for upsell modal v3
    private visibleSourceV3 = new BehaviorSubject<boolean>(false);
    visibleV3$: Observable<boolean> = this.visibleSourceV3.pipe(distinctUntilChanged());
    private dataV3 = new BehaviorSubject<Object>({});
    dataV3$: Observable<Object> = this.dataV3.pipe(distinctUntilChanged());

    // observable for upsell modal mobile
    private upsellModalVisibilityMobile = new BehaviorSubject<boolean>(false);
    upsellModalVisibilityMobile$: Observable<boolean> = this.upsellModalVisibilityMobile.pipe(distinctUntilChanged());
    private upsellModalDataMobile = new BehaviorSubject<Object>({});
    upsellModalDataMobile$: Observable<Object> = this.upsellModalDataMobile.pipe(distinctUntilChanged());

    // observable for upsell modal v4
    private visibleSourceV4 = new BehaviorSubject<boolean>(false);
    visibleV4$: Observable<boolean> = this.visibleSourceV4.pipe(distinctUntilChanged());
    private dataV4 = new BehaviorSubject<Object>({});
    dataV4$: Observable<Object> = this.dataV4.pipe(distinctUntilChanged());

    // data observable for discount upsell modal
    private dataDiscountModal = new BehaviorSubject<Object>({});
    dataDiscountModal$: Observable<Object> = this.dataDiscountModal.pipe(distinctUntilChanged());

    // visibility observable for discount upsell modal - desktop
    private visibleSourceDiscountModal = new BehaviorSubject<boolean>(false);
    visibleDiscountModal$: Observable<boolean> = this.visibleSourceDiscountModal.pipe(distinctUntilChanged());


    // visibility observable for discount upsell modal - mobile
    private visibleSourceDiscountModalMobile = new BehaviorSubject<boolean>(false);
    visibleDiscountModalMobile$: Observable<boolean> = this.visibleSourceDiscountModalMobile.pipe(distinctUntilChanged());

    // visibility observable for education upsell modal
    private visibleSourceEducationUpsellModal = new BehaviorSubject<boolean>(false);
    visibleEducationUpsellModal$: Observable<boolean> = this.visibleSourceEducationUpsellModal.pipe(distinctUntilChanged());

    hasCloseEvent: string = '';
    isOnSoloOrSoloTrialPlan: boolean = false;
    isOnQRTrialPlan: boolean = false;

    UPSELL_CLOSE_EVENT = UPSELL_CLOSE_EVENT;
    _isDesktop = window.innerWidth >= 768;
    get isDesktop() {
        return this._isDesktop;
    }

    updateIsDesktop() {
        this._isDesktop = window.innerWidth >= 768;
    }


    constructor(private service: AuthService) {}

    show(
        title,
        message,
        route?,
        primaryCta?,
        eventFromSource?,
        imageShow?: boolean,
        imageNameSource?: string,
        redirectLink?: string,
        closeEventSource?: UPSELL_CLOSE_EVENT,
        isTrialSwitchModalValue?: string,
        allowDirectUpgrade?: boolean,
        feature?,
    ) {
        this.visibleSource.next(true);
        this.messageSource.next(message);
        this.titleSource.next(title);
        this.routeToSource.next(route);
        if (primaryCta) {
            this.primaryCtaSource.next(primaryCta);
        }
        this.eventSource.next(eventFromSource);
        this.imageShowSource.next(imageShow);
        this.imageNameSource.next(imageNameSource);
        this.redirectLinkSource.next(redirectLink);
        this.hasCloseEvent = closeEventSource || '';
        this.isTrialSwitchModal.next(isTrialSwitchModalValue);
        this.allowDirectUpgrade.next(allowDirectUpgrade || false);
        this.feature.next(feature);
    }
    validateFeatureDataForDBC(data) {
        this.isOnSoloOrSoloTrialPlan  = this.service.getUser().isOnSoloPlan() || this.service.getUser().isOnSoloTrial();
        switch (data.feature) {
            case FEATURE_TYPES.ADVANCED_CUSTOMIZATIONS_NEW_CARD_DESIGNS:
                if (this.isOnSoloOrSoloTrialPlan) {
                    UPSELL_DATA.advanced_customizations_new_card_designs.BN.detail.save.text = 'Save 33%';
                }
                break;
            case FEATURE_TYPES.ADVANCED_CUSTOMIZATION:
                if (this.isOnSoloOrSoloTrialPlan) {
                    UPSELL_DATA.advanced_customization.BN.detail.save.text = 'Save 33%';
                }
                break;
        }
    }
    updateFeatureDataForQR(data) {
        this.isOnQRTrialPlan = this.service.getUser().isOnTrialPlan(PRODUCT_TYPES.QR);
        switch (data.feature) {
            case FEATURE_TYPES.MULTI_LANG:
                if (this.isOnQRTrialPlan) {
                    UPSELL_DATA.multi_lang[PLAN_TYPES.Pro].directToPricing = true;
                }
                break;
            case FEATURE_TYPES.LOCATION:
                if (this.isOnQRTrialPlan) {
                    UPSELL_DATA.location[PLAN_TYPES.Pro].directToPricing = true;
                    UPSELL_DATA.location[PLAN_TYPES.Pro].title.main = 'Track GPS with ';
                    UPSELL_DATA.location[PLAN_TYPES.Pro].description = 'See on a map the GPS location of scans';
                }
        }
    }
    showV2(data) {
        if (Object.values(FEATURE_TYPES).includes(data.feature as FEATURE_TYPES)) {
            this.validateFeatureDataForDBC(data);
            this.updateFeatureDataForQR(data);
        }
        this.dataV2.next(data);
        this.visibleSourceV2.next(true);
    }

    dismissV2() {
        this.visibleSourceV2.next(false);
    }

    showV3(data) {
        this.visibleSourceV3.next(true);
        this.dataV3.next(data);
    }

    dismissV3() {
        this.visibleSourceV3.next(false);
    }

    showUpsellV4(data) {
        this.visibleSourceV4.next(true);
        this.dataV4.next(data);
    }

    dismissUpsellV4() {
        this.visibleSourceV4.next(false);
    }

    // TODO: This is an overhead at, we can use the same method for mobile and desktop
    showUpsellModalOnMobile(data) {
        if (Object.values(FEATURE_TYPES).includes(data.feature as FEATURE_TYPES)) {
            this.validateFeatureDataForDBC(data);
        }
        this.upsellModalVisibilityMobile.next(true);
        this.upsellModalDataMobile.next(data);
    }

    dismissMobileModal() {
        this.upsellModalVisibilityMobile.next(false);
    }

    showDiscountModal(data) {
        this.updateIsDesktop();
        this.dataDiscountModal.next(data);
        if (this.isDesktop) {
            this.visibleSourceDiscountModal.next(true);
        } else {
            this.visibleSourceDiscountModalMobile.next(true);
        }
    }

    dismissDiscountModal() {
        this.visibleSourceDiscountModal.next(false);
        this.visibleSourceDiscountModalMobile.next(false);
    }

    dismiss() {
        this.visibleSource.next(false);
        this.messageSource.next('');
        this.titleSource.next('');
        this.primaryCtaSource.next('Upgrade Plan');
        this.imageShowSource.next(false);
        this.imageNameSource.next('');
        this.redirectLinkSource.next('');
        if (this.hasCloseEvent) {
            this.eventOnClose.next(this.hasCloseEvent);
        }
        this.hasCloseEvent = '';
        this.eventOnClose.next('');
        this.isTrialSwitchModal.next('');
    }

    minimumRequiredPlan(feature) {
        return this.minimumRequiredPlanList[feature];
    }

    isSupportedOnPlan(feature) {
        if (this.service.getUser().isOnQRPlan()) {
            return this.service.getUser().isOnHigherPlan(this.minimumRequiredPlan(feature));
        }
        return true;
    }

    isSupportedOnDBCPlan(feature) {
        if (this.service.getUser().isOnDBCPlan()) {
            return this.service.getUser().isOnHigherDBCPlan(this.minimumRequiredPlan(feature));
        }
        return true;
    }

    showEducationModal(data: EducationModalData) {
        this.visibleSourceEducationUpsellModal.next(true);
        this.messageSource.next(data.message);
        this.titleSource.next(data.title);
        this.routeToSource.next(data.route);

        if (data.primaryCta) {
            this.primaryCtaSource.next(data.primaryCta);
        }

        this.eventSource.next(data.eventFromSource);
        this.imageShowSource.next(data.imageShow);
        this.imageNameSource.next(data.imageNameSource);
        this.redirectLinkSource.next(data.redirectLink);
        this.hasCloseEvent = data.closeEventSource || '';
    }

    dismissEducationModal() {
        this.visibleSourceEducationUpsellModal.next(false);
        this.messageSource.next('');
        this.titleSource.next('');
        this.primaryCtaSource.next('Upgrade Plan');
        this.imageShowSource.next(false);
        this.imageNameSource.next('');
        this.redirectLinkSource.next('');
        if (this.hasCloseEvent) {
            this.eventOnClose.next(this.hasCloseEvent);
        }
        this.hasCloseEvent = '';
        this.eventOnClose.next('');
    }
}
