import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {UpsellService} from '../../global-services/upsell.service';
import {Subject, Subscription} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../global-services/auth.service';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {TrialSwitchService} from 'app/global-services/trial-switch.service';
import {UserService} from 'app/user-account/user.service';
import {PLAN_TYPES, User} from '../../user-account/user.model';
import {OverlayService} from '../../global-services/overlay.service';
import {RouterReloadService} from '../../global-services/router-reload.service';
import {
    TOOLTIP_POSITION,
    CARET_POSITION,
    BUTTON_STYLES,
    BUTTON_TYPES,
    MESSAGE_TYPES
} from 'kaizen-design-system';
import {PRODUCT_TYPES, isObjectEmpty, DASHBOARD_MENU, RETARGETING_TYPE} from '../../shared/utils';
import {UserSubscriptionService} from '../../global-services/user-subscription-service';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService} from '../../global-services/amplitude.service';
import {UPSELL_DATA, UPSELL_DATA_DISCOUNT_MODAL, UPSELL_DATA_FOR_V4} from './upsell-data.data';

enum UPSELL_MODAL {
    NONE = '',
    V1 = 'v1',
    V2 = 'v2',
    V3 = 'v3',
    MOBILE = 'mobile',
    DISCOUNT = 'discount',
    DISCOUNT_MOBILE = 'discount_mobile',
    EDUCATION = 'education'
}

enum TOOLTIP_MEDIA {
    VIDEO = 'video',
    IMAGE = 'image',
}

enum UPSELL_FEATURES {
    LOCATION = 'location',
    LOCATION_SPU = 'location_spu',
    MULTI_LANG = 'multi_lang',
    BULK_UPLOAD = 'bulk_upload',
    WHITE_LABEL = 'whitelabel_access',
    MFA = 'mfa_access',
    SSO = 'sso',
    MULTI_ORG = 'multi_org',
    RETARGETING = 'retargeting',
    MULTI_USER = 'multi_user',
    SPU = 'spu',

    // DBC Features
    CUSTOM_FONTS = 'custom_fonts',
    DBC_WHITELABEL_ACCESS = 'dbc_whitelabel_access',
    DBC_SSO = 'dbc_sso',
    DBC_CUSTOM_DOMAIN = 'dbc_custom_domain',
    SALESFORCE = 'salesforce',
    ENTRA_ID = 'entra_id',

}

@Component({
    selector: 'beaconstac-upsell',
    templateUrl: './upsell.component.html',
    styleUrls: ['./upsell.component.scss'],
})
export class UpsellComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('upsellModal', { static: true }) upsellModal: ModalDirective;
    visibilitySubscription: Subscription;
    ngUnsubscribe: Subject<any> = new Subject();

    // kaizen
    BUTTON_TYPES = BUTTON_TYPES;
    BUTTON_STYLES = BUTTON_STYLES;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION;
    BASE_IMAGE_URL = '../../assets/img/upsell-images/';
    imageEndpoint = '';
    currentOrganizationId: number;
    trialFirst: boolean = false;
    allowDirectUpgrades: boolean = false;

    @ViewChild('upsellModalV2', { static: true }) upsellModalV2: ModalDirective;
    visibilitySubscriptionV2: Subscription;
    modalV2Data: any;

    @ViewChild('upsellModalV3', { static: true }) upsellModalV3: ModalDirective;
    visibilitySubscriptionV3: Subscription;
    modalV3Data: any;

    @ViewChild('upsellModalMobile', {static: true}) upsellModalMobile: ModalDirective;
    mobileUpsellModalData: any;
    isMobileUpsellModalOpen: boolean = false;

    @ViewChild('upsellModalV4', { static: true }) upsellModalV4: ModalDirective;
    visibilitySubscriptionV4: Subscription;
    modalV4Data: any;

    @ViewChild('discountUpsellModal', { static: true }) discountUpsellModal: ModalDirective;
    visibilitySubscriptionDiscountModal: Subscription;
    discountModalData: any;

    @ViewChild('discountUpsellModalMobile', {static: true}) discountUpsellModalMobile: ModalDirective;
    isDiscountUpsellModalMobileOpen: boolean = false;

    @ViewChild('educationUpsellModal', {static: true}) educationUpsellModal: ModalDirective;
    visibilityEducationUpsellSubscription: Subscription;

    user: User;
    upsellData = UPSELL_DATA;

    upsellDataForV4 = UPSELL_DATA_FOR_V4;
    upsellDataForDiscountModal =  UPSELL_DATA_DISCOUNT_MODAL;

    PLAN_TYPES = PLAN_TYPES;
    renderedModal: UPSELL_MODAL;
    feature: string = '';
    minRequiredPlanForFeature: PLAN_TYPES;
    source: string = '';
    TOOLTIP_MEDIA = TOOLTIP_MEDIA;
    isObjectEmpty = isObjectEmpty;
    retargetingType: RETARGETING_TYPE;
    MESSAGE_TYPES = MESSAGE_TYPES;
    reloadPage: boolean = false;
    isSpuSubscriptionEndDateBetweenJan20AndMar6: boolean = false;

    constructor(
        public service: UpsellService,
        private router: Router,
        private authService: AuthService,
        private messageModal: MessageModalService,
        public trailSwitchService: TrialSwitchService,
        private userService: UserService,
        private overlay: OverlayService,
        private routerReloadService: RouterReloadService,
        private route: ActivatedRoute,
        private userSubscriptionService: UserSubscriptionService,
        private amplitude: AmplitudeService,
    ) {
        this.currentOrganizationId = this.authService.getCurrentOrgId();
        this.user = this.authService.getUser();
    }

    ngOnInit() {
        this.isSpuSubscriptionEndDateBetweenJan20AndMar6 = this.isSPUEndDateBetweenJan20AndMar6();
        this.service.imageName$.subscribe((name) => {
            this.imageEndpoint = name;
        });
        this.route.queryParams.subscribe((params) => {
            this.trialFirst = params.trial_first;
        });
        this.service.allowDirectUpgrade$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((directUpgrade) => {
            this.allowDirectUpgrades = directUpgrade;
        });
        this.service.source$.pipe(take(1)).subscribe((value) => {
            this.source = value;
        });
        this.service.feature$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((feature) => {
            this.feature = feature;
            this.minRequiredPlanForFeature = this.service.minimumRequiredPlan(feature);
        });
        this.service.dataV2$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
            this.feature = res.feature;
            this.minRequiredPlanForFeature = this.service.minimumRequiredPlan(this.feature);
            if (this.feature === UPSELL_FEATURES.LOCATION_SPU) {
                this.minRequiredPlanForFeature = PLAN_TYPES.Pro;
            }
            this.source = res.source;
            this.modalV2Data = this.upsellData[this.feature]?.[this.minRequiredPlanForFeature];
            this.retargetingType = res.retargetingType || null;
            this.reloadPage = res.reload;
        });
        this.service.dataV3$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
            this.feature = res.feature;
            this.minRequiredPlanForFeature = this.service.minimumRequiredPlan(this.feature);
            this.source = res.source;
            this.modalV3Data = this.upsellData[this.feature]?.[this.minRequiredPlanForFeature];
        });
        this.service.upsellModalDataMobile$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
            this.feature = res.feature;
            this.minRequiredPlanForFeature = this.service.minimumRequiredPlan(this.feature);
            this.source = res.source;
            this.mobileUpsellModalData = this.upsellData[this.feature]?.[this.minRequiredPlanForFeature];
            this.retargetingType = res.retargetingType || null;
        });
        this.service.dataV4$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
            this.feature = res.feature;
            this.minRequiredPlanForFeature = this.service.minimumRequiredPlan(this.feature);
            this.source = res.source;
            this.modalV4Data = this.upsellDataForV4[this.feature]?.[this.minRequiredPlanForFeature];
        });
        this.service.dataDiscountModal$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
            this.feature = res.feature;
            this.minRequiredPlanForFeature = this.service.minimumRequiredPlan(this.feature);
            this.source = res.source;
            this.discountModalData = this.upsellDataForDiscountModal[this.feature]?.[this.minRequiredPlanForFeature];
            this.reloadPage = res.reload;
        });
    }

    ngAfterViewInit() {
        this.visibilitySubscription = this.service.visible$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((visible) => {
            if (visible) {
                this.renderedModal = UPSELL_MODAL.V1;
                this.upsellModal.show();
            } else {
                this.renderedModal = UPSELL_MODAL.NONE;
                this.upsellModal.hide();
            }
        });

        this.visibilityEducationUpsellSubscription = this.service.visibleEducationUpsellModal$
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe((visible) => {
                this.renderedModal = visible ? UPSELL_MODAL.EDUCATION : UPSELL_MODAL.NONE;
                visible ? this.educationUpsellModal.show() : this.educationUpsellModal.hide();
            });

        this.visibilitySubscriptionV2 = this.service.visibleV2$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((visible) => {
                if (visible) {
                    this.renderedModal = UPSELL_MODAL.V2;
                    this.upsellModalV2.show();
                } else {
                    this.renderedModal = UPSELL_MODAL.NONE;
                    this.upsellModalV2.hide();
                }
            });

        this.visibilitySubscriptionV3 = this.service.visibleV3$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(visible => {
            if (visible) {
                this.renderedModal = UPSELL_MODAL.V3;
                this.upsellModalV3.show();
            } else {
                this.renderedModal = UPSELL_MODAL.NONE;
                this.upsellModalV3.hide();
            }
        });

        this.service.upsellModalVisibilityMobile$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(visible => {
            if (visible) {
                this.renderedModal = UPSELL_MODAL.MOBILE;
                this.isMobileUpsellModalOpen = true;
            } else {
                this.renderedModal = UPSELL_MODAL.NONE;
                this.isMobileUpsellModalOpen = false;
            }
        });
        this.visibilitySubscriptionV3 = this.service.visibleV3$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((visible) => {
                if (visible) {
                    this.renderedModal = UPSELL_MODAL.V3;
                    this.upsellModalV3.show();
                } else {
                    this.renderedModal = UPSELL_MODAL.NONE;
                    this.upsellModalV3.hide();
                }
            });

        this.visibilitySubscriptionV4 = this.service.visibleV4$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((visible) => {
                if (visible) {
                    this.renderedModal = UPSELL_MODAL.V3;
                    this.upsellModalV4.show();
                } else {
                    this.renderedModal = UPSELL_MODAL.NONE;
                    this.upsellModalV4.hide();
                }
            });

        this.visibilitySubscriptionDiscountModal = this.service.visibleDiscountModal$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((visible) => {
                if (visible) {
                    this.renderedModal = UPSELL_MODAL.DISCOUNT;
                    this.discountUpsellModal.show();
                } else {
                    this.renderedModal = UPSELL_MODAL.NONE;
                    this.discountUpsellModal.hide();
                }
            });

        this.service.visibleDiscountModalMobile$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(visible => {
            if (visible) {
                this.renderedModal = UPSELL_MODAL.DISCOUNT_MOBILE;
                this.isDiscountUpsellModalMobileOpen = true;
            } else {
                this.renderedModal = UPSELL_MODAL.NONE;
                this.isDiscountUpsellModalMobileOpen = false;
            }
        });
    }

    ngOnDestroy() {
        this.onHide();
        if (this.visibilitySubscription) {
            this.visibilitySubscription.unsubscribe();
        }
        this.onHideV2();
        if (this.visibilitySubscriptionV2) {
            this.visibilitySubscriptionV2.unsubscribe();
        }
        this.onHideV3();
        if (this.visibilitySubscriptionV3) {
            this.visibilitySubscriptionV3.unsubscribe();
        }
        this.onHideMobileModal();

        this.onHideUpsellV4();
        if (this.visibilitySubscriptionV4) {
            this.visibilitySubscriptionV4.unsubscribe();
        }

        this.onHideDiscountModal();
        if (this.visibilitySubscriptionDiscountModal) {
            this.visibilitySubscriptionDiscountModal.unsubscribe();
        }

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onHide() {
        this.service.routeTo$.pipe(take(1)).subscribe((res) => {
            if (res) {
                this.router.navigate([res], { queryParams: { orgID: this.currentOrganizationId } });
            }
            this.service.dismiss();
        });
    }

    onHideV2() {
        this.service.dismissV2();
    }

    onHideUpsellV4() {
        this.service.dismissUpsellV4();
    }

    onHideV3() {
        this.service.dismissV3();
    }

    onHideMobileModal() {
        this.isMobileUpsellModalOpen = false;
        this.service.dismissMobileModal();
    }

    onHideDiscountModal() {
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.SPU_BANNER_CLOSE);
        this.service.dismissDiscountModal();
    }

    onHideEducationModal() {
        this.service.dismissEducationModal();
    }

    routeToChangePlan() {
        if (this.allowDirectUpgrades) {
            this.handleDirectUpgrade();
            return;
        }
        this.service.isTrialSwitchModal$.pipe(take(1)).subscribe((isTrialSwitchModal) => {
            if (isTrialSwitchModal) {
                this.overlay.isLoading(true);
                this.upsellModal.hide();
                const data = {
                    plan: isTrialSwitchModal,
                }
                this.trailSwitchService.post(data).subscribe(res => {
                    this.pollUserUntilTrialSwitched(isTrialSwitchModal, 5);
                    this.messageModal.show('Plan upgraded successfully', 'success');
                    this.service.isTrialSwitchModal.next(null)
                }, error => {
                    console.error(error);
                    this.messageModal.show('Error upgrading plan. Please contact support', 'danger');
                    this.overlay.isLoading(false);
                    this.service.isTrialSwitchModal.next(null)
                })
            } else {
                this.onHide();
                if (this.authService.getUser().isOwner()) {
                    this.service.source$.pipe(take(1)).subscribe((value) => {
                        if (this.trialFirst) {
                            window.open(`/account/upgrade?source=${value}`, '_blank');
                        } else {
                            this.openUpgradePage(value);
                        }
                    });
                } else {
                    this.messageModal.show(
                        'This is a restricted feature, please contact your account owner.',
                        'warning',
                    );
                }
            }
            this.onHide();
            this.onHideV2();
            this.onHideV3();
        });
    }

    openUpgradePage(source) {
        window.open(`/account/upgrade?source=${source}&orgId=${this.currentOrganizationId}`, '_blank', 'noopener noreferrer');
        this.service.eventSource.next('')
    }

    pollUserUntilTrialSwitched(plan, count) {
        this.userService
            .getDetail()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                const user = new User(res);
                if (user.dbcCustomerPlan !== plan && count > 0) {
                    setTimeout(() => {
                        this.pollUserUntilTrialSwitched(plan, count - 1);
                    }, 500);
                    return;
                } else {
                    this.overlay.isLoading(false);
                    this.routerReloadService.reload();
                    this.onHide();
                }
            });
    }

    redirectTo() {
        let url = '';
        this.service.redirectLink$.subscribe((res) => {
            url = res;
        });
        window.open(url, '_blank');
    }

    onToggleCustomDomainAddon($event){
        this.modalV2Data.buyWithAddon.checked = $event.target.checked;
    }

    isSPUEndDateBetweenJan20AndMar6(): boolean {
        if (this.user?.meta?.spu_subscription_end_date) {
            const rawTimestamp = this.user.meta.spu_subscription_end_date;
            const spuEndDate = new Date(rawTimestamp * 1000);
            if (isNaN(spuEndDate.getTime())) {
                console.error('Invalid date format:', rawTimestamp);
                return false;
            }
            const jan20 = new Date('2025-01-20T00:00:00Z'); // Jan 20, 2025 UTC
            const mar6 = new Date('2025-03-06T23:59:59Z'); // Mar 6, 2025 UTC

            return spuEndDate >= jan20 && spuEndDate <= mar6;
        }
        return false;
    }

    handleDirectUpgrade() {
        if (!this.authService.getUser().isOwner()) {
            this.messageModal.show('This is a restricted feature, please contact your account owner.', 'warning');
            return;
        }

        if (this.service.minimumRequiredPlan(this.feature) === PLAN_TYPES.Enterprise || this.service.minimumRequiredPlan(this.feature) === PLAN_TYPES.BusinessPlus) {
            this.navigateToScheduleDemoPage();
            return;
        }
        const product_type: string = this.authService.getCurrentProduct();
        if (product_type === DASHBOARD_MENU.CARDS) {
            this.routeToChangePlan();
            return;
        }
        this.onHide();
        this.onHideV2();
        this.onHideV3();
        this.onHideUpsellV4();
        this.onHideDiscountModal();
        this.overlay.isLoading(true);
        const isOnQRTrialPlan = this.user.isOnTrialPlan(PRODUCT_TYPES.QR);
        const additionalParams = { };
        let coupon = null;

        if (this.modalV2Data?.buyWithAddon?.checked){
            additionalParams['additionalCustomDomains'] = 1;
        }

        if (this.modalV2Data?.coupon) {
            coupon = this.modalV2Data.coupon;
        }

        if (this.discountModalData?.coupon) {
            coupon = this.discountModalData.coupon;
        }
        // checking if the user's spu subscription end date is between Jan 20 and Mar 6 then only we will give them 50% coupon code , cause users whose spu subscription end date is between Jan 20 and Mar 6 have recieved a mail regarding discount.
        if (!this.isSpuSubscriptionEndDateBetweenJan20AndMar6) {
            coupon = null;
        }

        if (!isOnQRTrialPlan) {
            this.userService.updateSubscription(this.minRequiredPlanForFeature, true, coupon, this.source, null, null, null, additionalParams).subscribe(
                () => {
                    this.overlay.isLoading(false);
                    const message = additionalParams['additionalCustomDomains'] ?
                        'Plan upgraded! Updates on Custom Domain will take a while to reflect, please check back later.' :
                        'Plan upgraded successfully!';

                    this.messageModal.show(message, 'success', 4000);
                    this.userSubscriptionService.getSubscriptionDetails();
                    this.pollQRUserUntilPlanChanged(this.minRequiredPlanForFeature, 5);
                    this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'Upgrade_successful', {
                        value: this.getUpgradeSourceValue(),
                    });
                    if (this.reloadPage) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 4000);
                    }
                },
                (error) => {
                    this.overlay.isLoading(false);
                    this.messageModal.show('Failed to upgrade. Please contact support@uniqode.com.', 'danger', 10000);
                    this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'Upgrade_unsuccessful', {
                        value: this.getUpgradeSourceValue(),
                    });
                },
            );
        } else {
            this.userService
                .subscribeWithCheckout(this.minRequiredPlanForFeature, true, this.source, null, null, null, null, additionalParams)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    (res) => {
                        this.overlay.isLoading(false);
                        window.location.href = res.redirectUrl;
                    },
                    (errorResponse) => {
                        this.overlay.isLoading(false);
                        this.authService.addDatadogAction('SubscriptionUpgradeFailed');
                        this.messageModal.show(
                            'Failed to upgrade. Please contact support@uniqode.com.\n' +
                                errorResponse.error.error.error,
                            'danger',
                            10000,
                        );
                    },
                );
        }
    }

    pollQRUserUntilPlanChanged(plan, count) {
        this.userService
            .getDetail()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user) => {
                if (user.qrCustomerPlan !== plan && count > 0) {
                    setTimeout(() => {
                        this.pollQRUserUntilPlanChanged(plan, count - 1);
                    }, 500);
                    return;
                } else {
                    this.overlay.isLoading(false);
                    this.routerReloadService.reload();
                    this.onHideV2();
                }
            });
    }

    handleUpsellRedirect() {
        window.open(
            `/account/upgrade?source=${this.source}&orgId=${this.currentOrganizationId}`,
            '_blank',
            'noopener noreferrer',
        );
    }

    getUpgradeSourceValue() {
        switch (this.feature) {
            case UPSELL_FEATURES.BULK_UPLOAD:
                return 'Bulk Upload';
            case UPSELL_FEATURES.LOCATION || UPSELL_FEATURES.LOCATION_SPU:
                return 'GPS';
            case UPSELL_FEATURES.MULTI_LANG:
                return 'Multi-language';
            case UPSELL_FEATURES.SPU:
                return 'spu_banner_upgrade'
            default:
                return '-1';
        }
    }

    getAmplitudeEventValue() {
        switch (this.feature) {
            case UPSELL_FEATURES.WHITE_LABEL:
                return 'Whitelabel';
            case UPSELL_FEATURES.MFA:
                return 'MFA';
            case UPSELL_FEATURES.SSO:
                return 'SSO';
            case UPSELL_FEATURES.MULTI_ORG:
                return 'Organization';
            case UPSELL_FEATURES.RETARGETING:
                return this.getRetargetingAmplitudeEventValue();
            case UPSELL_FEATURES.DBC_WHITELABEL_ACCESS:
                return UPSELL_FEATURES.DBC_WHITELABEL_ACCESS;
            case UPSELL_FEATURES.DBC_SSO:
                return UPSELL_FEATURES.DBC_SSO;
            case UPSELL_FEATURES.DBC_CUSTOM_DOMAIN:
                return UPSELL_FEATURES.DBC_CUSTOM_DOMAIN;
            case UPSELL_FEATURES.SALESFORCE:
                return 'dbc_salesforce';
            case UPSELL_FEATURES.ENTRA_ID:
                return 'dbc_entra_id';
            case UPSELL_FEATURES.CUSTOM_FONTS:
                return 'dbc_custom_fonts';
            default:
                return '-1';
        }
    }

    getRetargetingAmplitudeEventValue() {
        switch (this.retargetingType) {
            case RETARGETING_TYPE.FACEBOOK_PIXEL:
                return 'Integrations_fbpixel';
            case RETARGETING_TYPE.GOOGLE_ADS:
                return 'Integrations_google';
            case RETARGETING_TYPE.LINKPAGE_FACEBOOK_PIXEL:
                return 'Linkpage_fbpixel';
            case RETARGETING_TYPE.LINKPAGE_GOOGLE_ADS:
                return 'Linkpage_google';
            default:
                return '-1';
        }
    }

    getUTMCampaign(): string {
        switch (this.feature) {
            case UPSELL_FEATURES.WHITE_LABEL:
                return 'whitelabel';
            case UPSELL_FEATURES.MFA:
                return 'MFA';
            case UPSELL_FEATURES.SSO:
                return 'SSO';
            case UPSELL_FEATURES.MULTI_ORG:
                return 'organization';
            case UPSELL_FEATURES.RETARGETING:
                return this.getRetargetingUTMCampaign();

            case UPSELL_FEATURES.DBC_WHITELABEL_ACCESS:
                return UPSELL_FEATURES.DBC_WHITELABEL_ACCESS;
            case UPSELL_FEATURES.DBC_SSO:
                return UPSELL_FEATURES.DBC_SSO;
            case UPSELL_FEATURES.DBC_CUSTOM_DOMAIN:
                return UPSELL_FEATURES.DBC_CUSTOM_DOMAIN;
            case UPSELL_FEATURES.SALESFORCE:
                return 'dbc_salesforce';
            case UPSELL_FEATURES.ENTRA_ID:
                return 'dbc_entra_id';
            case UPSELL_FEATURES.CUSTOM_FONTS:
                return 'dbc_custom_fonts';
            default:
                return '-1';
        }
    }

    private getRetargetingUTMCampaign(): string {
        switch (this.retargetingType) {
            case RETARGETING_TYPE.FACEBOOK_PIXEL:
                return 'integrations_fbpixel';
            case RETARGETING_TYPE.GOOGLE_ADS:
                return 'integrations_google';
            case RETARGETING_TYPE.LINKPAGE_FACEBOOK_PIXEL:
                return 'linkpage_fbpixel';
            case RETARGETING_TYPE.LINKPAGE_GOOGLE_ADS:
                return 'linkpage_google';
            default:
                return '-1';
        }
    }

    navigateToScheduleDemoPage(): void {
        const baseUrl = 'https://www.uniqode.com/schedule-demo';

        const queryParams = {
            utm_source: 'Dashboard',
            utm_medium: 'salestouchpoints',
            utm_campaign: this.getUTMCampaign(),
            user_data: this.getEncodedUserData()
        };

        const queryString: string = this.constructQueryParams(queryParams);
        const url = `${baseUrl}?${queryString}`;
        window.open(url, '_blank');
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'ScheduleDemo', {
            value: this.getAmplitudeEventValue(),
        });
    }

    constructQueryParams(params: { [key: string]: any }): string {
        return Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
    }

    getEncodedUserData() {
        const user_data = {
            email: this.user.email,
            firstName: this.user.first_name,
            lastName: this.user.last_name,
            companySize: this.user.meta.company_size === 'Opted out' ? '' : this.user.meta.company_size,
            industry: this.user.meta.industry === 'Opted out' ? '' : this.user.meta.industry,
            companyName: this.user.organization.name,
            source: 'dashboard',
        };
        const userDataString = JSON.stringify(user_data);
        const encodedUserData = btoa(userDataString);
        return encodedUserData;
    }
}
